<template>
  <b-card no-body>
    <b-card-body class="d-flex align-items-center p-2">
      <b-avatar
        class="mx-1"
        :variant="`light-${color}`"
        size="45"
      >
        <feather-icon
          size="21"
          :icon="icon"
        />
      </b-avatar>
      <div class="truncate">
        <div v-if="isTaux == true">
          <h4 class="font-weight-bolder" >
          {{ statistic }} %
        </h4>
      </div>
        <div v-else>
          <h4 class="font-weight-bolder" >
          {{ statistic }}
          </h4>
        </div>
        
        <div><span class="font-small-5">{{ statisticTitle }}</span></div>
        <div><span class="font-small-5 font-weight-bolder text-info">{{ statisticDescription}}</span></div>  
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import { BCard, BCardBody, BAvatar } from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BCardBody,
    BAvatar,
  },
  props: {
    icon: {
      type: String,
      required: true,
    },
    isTaux:{
      type: Boolean,
      default: false,
      required: true
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    statisticDescription: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
